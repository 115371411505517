import React from 'react';
import { connect } from 'react-redux';
import '../styles/navigation.css';
import { getCityAdminSettings } from '../firebase/cityAdminSettings';
import { getUser } from '../firebase/users';
import { auth } from '../firebase/index';
import TraceabilityMenu from './TraceabilityMenu';
import PrincipalMenu from './PrincipalMenu';
import CenterMenu from './CentersMenu';

class Navigation extends React.Component {
  rootSubmenuKeys = ['sub1', 'sub2'];

  state = {
    current: 'home',
    collapsed: false,
    openKeys: [],
    citySettings: null,
    admin: false,
    authenticated: false,
    isTraceability: false,
    role: null,
    cityRole: null,
    category: null,
    cocaCola: false,
    isCenter: false,
  };

  settings = async () => {
    if (this.props.isAuthenticated) {
      const uid = auth.currentUser ? auth.currentUser.uid : null;
      const user = await getUser(uid);
      let citySettingsArray = [];
      let citySettingsData = null;
      getCityAdminSettings().once('value', cityAdminSettingsSnap => {
        citySettingsData = cityAdminSettingsSnap;
        cityAdminSettingsSnap.forEach(citySettings => {
          if (citySettings.key === user.val().city) {
            citySettingsArray = citySettings.val();
          }
        });
        this.setState({
          citySettings: citySettingsArray,
          admin: user.val().admin,
          isTraceability: user.val().isTraceability,
          role: user.val().profile.role?user.val().profile.role:null,
          cityRole: user.val().profile.cityRole?user.val().profile.cityRole:null,
          category: user.val().profile.category?user.val().profile.category:null,
          cocaCola: !!user.val().cocaCola,
          premiumClient: !!user.val().isPremiumClient,
          isCenter: user.val().isCenter? true: null,
        });
      });
    } else {
      this.setState({
        citySettings: null,
        admin: false,
        isTraceability: false,
      });
    }
  };

  componentDidMount = async () => {
    await this.settings();
  };

  componentDidUpdate = async prevProps => {
    if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
      await this.settings();
    }
  };

  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(
      key => this.state.openKeys.indexOf(key) === -1,
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  handleClick = e => {
    this.setState({
      current: e.key,
    });
  };

  render() {
    return this.props.isAuthenticated ? (
      this.state.isTraceability ? (
        <TraceabilityMenu
          state={this.state}
          handleClick={this.handleClick}
          onOpenChange={this.onOpenChange}
        />
      ) : this.state.isCenter ? (
        <CenterMenu
          state={this.state}
          handleClick={this.handleClick}
          onOpenChange={this.onOpenChange}
        />
      ) : (
        <PrincipalMenu
          state={this.state}
          handleClick={this.handleClick}
          onOpenChange={this.onOpenChange}
        />
      )
    ) : null;
  }
}

const mapStateToProps = state => ({
  isAuthenticated: !!state.auth.uid,
});

export default connect(mapStateToProps)(Navigation);
