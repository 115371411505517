import { db } from '../firebase';

export const getCityAdminSettings = () => db.ref('cityAdminSettings');

export const createCityAdminSettings = city =>
  db.ref(`/cityAdminSettings/${city}`).set({
    about: {
      active: false,
    },
    associations: {
      active: false,
    },
    campaigns: {
      active: false,
    },
    citiesSettings: {
      active: false,
    },
    collectors: {
      active: false,
    },
    commerceUsers: {
      active: false,
      reports: false,
      deliveries: false,
    },
    containers: {
      active: false,
    },
    deliveryPoints: {
      active: false,
      cleanPoints: false,
      iglus: false,
      pichaybots: false,
    },
    experiments: {
      active: false,
      comparison: false,
      festivityGroups: false,
      groups1: false,
      groups2: false,
    },
    home: {
      active: false,
    },
    intermediaries: {
      active: false,
      createNewProfile: false,
      transactions: false,
    },
    materialDeliveries: {
      active: false,
      deliveriesRequests: false,
      normalDeliveries: false,
      subscriptions: false,
    },
    recinegocios: {
      active: false,
      createNewReciNegocio: false,
      exchanges: false,
    },
    recyclers: {
      active: false,
      normalRecyclers: false,
      qrRecyclers: false,
    },
    recyclingStations: {
      active: false,
      centers: {
        active: false,
        deliveries: false,
      },
      pams: {
        active: false,
        deliveries: false,
      },
      profiles: {
        active: false,
        noQR: false,
        premiumClients: false,
        withQR: false,
      },
      returnability: false,
      stores: {
        active: false,
        deliveries: false,
      },
    },
    reports: {
      active: false,
      deliveries: false,
      habits: false,
      mapReports: false,
      moreActiveUsers: false,
      mostRecyclerUser: false,
      commerceDeliveries: false,
    },
    route: {
      active: false,
    },
    staticRoutes: {
      active: false,
      routesProfiles: false,
      routesDeliveries: false,
    },
    transformers: {
      active: false,
    },
    users: {
      active: false,
      usersInfo: false,
      usersPerCity: false,
      commerceReports: false,
    },
  });

export const setCityAdminSettings = id =>
  db.ref(`cityAdminSettings/${id}/home`).set({ active: true });

//home
export const activateHome = id => {
  db.ref(`cityAdminSettings/${id}/home`).update({ active: true });
};

export const desactivateHome = id => {
  db.ref(`cityAdminSettings/${id}/home`).update({ active: false });
};

//users
export const activateUsers = id => {
  db.ref(`cityAdminSettings/${id}/users`).update({ active: true });
  db.ref(`cityAdminSettings/${id}/users`).update({ usersInfo: true });
  db.ref(`cityAdminSettings/${id}/users`).update({ usersPerCity: true });
  db.ref(`cityAdminSettings/${id}/users`).update({ commerceUsers: true });
};

export const desactivateUsers = id => {
  db.ref(`cityAdminSettings/${id}/users`).update({ active: false });
  db.ref(`cityAdminSettings/${id}/users`).update({ usersInfo: false });
  db.ref(`cityAdminSettings/${id}/users`).update({ usersPerCity: false });
  db.ref(`cityAdminSettings/${id}/users`).update({ commerceUsers: false });
};

export const activateUsersInfo = id =>
  db.ref(`cityAdminSettings/${id}/users`).update({ usersInfo: true });

export const desactivateUsersInfo = id =>
  db.ref(`cityAdminSettings/${id}/users`).update({ usersInfo: false });

export const activateUsersPerCity = id =>
  db.ref(`cityAdminSettings/${id}/users`).update({ usersPerCity: true });

export const desactivateUsersPerCity = id =>
  db.ref(`cityAdminSettings/${id}/users`).update({ usersPerCity: false });

export const activateCommerceReports = id =>
  db.ref(`cityAdminSettings/${id}/users`).update({ commerceReports: true });

export const deactivateCommerceReports = id =>
  db.ref(`cityAdminSettings/${id}/users`).update({ commerceReports: false });

export const activateCommerceUsers = id =>
  db
    .ref(`cityAdminSettings/${id}/commerceUsers`)
    .update({ commerceUsers: true });

export const deactivateCommerceUsers = id =>
  db
    .ref(`cityAdminSettings/${id}/commerceUsers`)
    .update({ commerceUsers: false });

export const activateCommerceUsersDeliveries = id =>
  db.ref(`cityAdminSettings/${id}/commerceUsers`).update({ deliveries: true });

export const deactivateCommerceUsersDeliveries = id =>
  db.ref(`cityAdminSettings/${id}/commerceUsers`).update({ deliveries: false });

//recyclers
export const activateRecyclers = id => {
  db.ref(`cityAdminSettings/${id}/recyclers`).update({ active: true });
  db.ref(`cityAdminSettings/${id}/recyclers`).update({ normalRecyclers: true });
  db.ref(`cityAdminSettings/${id}/recyclers`).update({ qrRecyclers: true });
};

export const desactivateRecyclers = id => {
  db.ref(`cityAdminSettings/${id}/recyclers`).update({ active: false });
  db.ref(`cityAdminSettings/${id}/recyclers`).update({
    normalRecyclers: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclers`).update({ qrRecyclers: false });
};

export const activateNormalRecyclers = id => {
  db.ref(`cityAdminSettings/${id}/recyclers`).update({ normalRecyclers: true });
};

export const deactivateNormalRecyclers = id => {
  db.ref(`cityAdminSettings/${id}/recyclers`).update({
    normalRecyclers: false,
  });
};

export const activateQrRecyclers = id => {
  db.ref(`cityAdminSettings/${id}/recyclers`).update({ qrRecyclers: true });
};

export const deactivateQrRecyclers = id => {
  db.ref(`cityAdminSettings/${id}/recyclers`).update({ qrRecyclers: false });
};

//collectors
export const activateCollectors = id =>
  db.ref(`cityAdminSettings/${id}/collectors`).update({ active: true });

export const desactivateCollectors = id =>
  db.ref(`cityAdminSettings/${id}/collectors`).update({ active: false });

//Rutas Estáticas
export const activateStaticRoutes = id => {
  db.ref(`cityAdminSettings/${id}/staticRoutes`).update({ active: true });
  db.ref(`cityAdminSettings/${id}/staticRoutes`).update({
    routesProfiles: true,
  });
  db.ref(`cityAdminSettings/${id}/staticRoutes`).update({
    routesDeliveries: true,
  });
};
export const desactivateStaticRoutes = id => {
  db.ref(`cityAdminSettings/${id}/staticRoutes`).update({ active: false });
  db.ref(`cityAdminSettings/${id}/staticRoutes`).update({
    routesProfiles: false,
  });
  db.ref(`cityAdminSettings/${id}/staticRoutes`).update({
    routesDeliveries: false,
  });
};

//intermediaries
export const activateIntermediaries = id => {
  db.ref(`cityAdminSettings/${id}/intermediaries`).update({ active: true });
  db.ref(`cityAdminSettings/${id}/intermediaries`).update({
    createNewProfile: true,
  });
  db.ref(`cityAdminSettings/${id}/intermediaries`).update({
    transactions: true,
  });
};

export const desactivateIntermediaries = id => {
  db.ref(`cityAdminSettings/${id}/intermediaries`).update({ active: false });
  db.ref(`cityAdminSettings/${id}/intermediaries`).update({
    createNewProfile: false,
  });
  db.ref(`cityAdminSettings/${id}/intermediaries`).update({
    transactions: false,
  });
};

//Containers
export const activateContainers = id =>
  db.ref(`cityAdminSettings/${id}/containers`).update({ active: true });

export const deactivateContainers = id =>
  db.ref(`cityAdminSettings/${id}/containers`).update({ active: false });

//Delivery Points
export const activateDeliveryPoints = id => {
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({ active: true });
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({
    cleanPoints: true,
  });
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({ iglus: true });
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({ pichaybots: true });
};

export const deactivateDeliveryPoints = id => {
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({ active: false });
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({
    cleanPoints: false,
  });
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({ iglus: false });
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({
    pichaybots: false,
  });
};

export const activateDeliveryPointsCleanPoints = id => {
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({ active: true });
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({
    cleanPoints: true,
  });
};

export const deactivateDeliveryPointsCleanPoints = id => {
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({
    cleanPoints: false,
  });
};

export const activateDeliveryPointsIglus = id => {
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({ active: true });
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({ iglus: true });
};

export const deactivateDeliveryPointsIglus = id => {
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({ iglus: false });
};

export const activateDeliveryPointsPichayBots = id => {
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({ active: true });
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({ pichaybots: true });
};

export const deactivateDeliveryPointsPichayBots = id => {
  db.ref(`cityAdminSettings/${id}/deliveryPoints`).update({
    pichaybots: false,
  });
};

export const activateIntermediariesNewProfile = id =>
  db
    .ref(`cityAdminSettings/${id}/intermediaries`)
    .update({ createNewProfile: true });

export const desactivateIntermediariesNewProfile = id =>
  db
    .ref(`cityAdminSettings/${id}/intermediaries`)
    .update({ createNewProfile: false });

export const activateIntermediariesTransactions = id =>
  db
    .ref(`cityAdminSettings/${id}/intermediaries`)
    .update({ transactions: true });

export const desactivateIntermediariesTransactions = id =>
  db
    .ref(`cityAdminSettings/${id}/intermediaries`)
    .update({ transactions: false });

//transformers
export const activateTransformers = id =>
  db.ref(`cityAdminSettings/${id}/transformers`).update({ active: true });

export const desactivateTransformers = id =>
  db.ref(`cityAdminSettings/${id}/transformers`).update({ active: false });

//associations
export const activateAssociations = id =>
  db.ref(`cityAdminSettings/${id}/associations`).update({ active: true });

export const desactivateAssociations = id =>
  db.ref(`cityAdminSettings/${id}/associations`).update({ active: false });

//campaigns
export const activateCampaigns = id =>
  db.ref(`cityAdminSettings/${id}/campaigns`).update({ active: true });

export const deactivateCampaigns = id =>
  db.ref(`cityAdminSettings/${id}/campaigns`).update({ active: false });

//cities
export const activateCitiesSettings = id =>
  db.ref(`cityAdminSettings/${id}/citiesSettings`).update({ active: true });

export const desactivateCitiesSettings = id =>
  db.ref(`cityAdminSettings/${id}/citiesSettings`).update({ active: false });

//recycling stations
export const activateRecyclingStations = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations`).update({ active: true });
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    active: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    withQR: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    noQR: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    premiumClients: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/stores`).update({
    active: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/stores`).update({
    deliveries: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/pams`).update({
    active: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/pams`).update({
    deliveries: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/centers`).update({
    active: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/centers`).update({
    deliveries: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations`).update({
    returnability: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations`).update({
    cleanPoints: true,
  });
};

export const desactivateRecyclingStations = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations`).update({ active: false });
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    active: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    withQR: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    noQR: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    premiumClients: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/stores`).update({
    active: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/stores`).update({
    deliveries: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/pams`).update({
    active: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/pams`).update({
    deliveries: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/centers`).update({
    active: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/centers`).update({
    deliveries: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations`).update({
    returnability: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations`).update({
    cleanPoints: false,
  });
};

export const activateRecyclingStationsProfiles = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    active: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    withQR: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    noQR: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    premiumClients: true,
  });
};

export const desactivateRecyclingStationsProfiles = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    active: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    withQR: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    noQR: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    premiumClients: false,
  });
};

export const activateRecyclingStationsProfilesQR = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    withQR: true,
  });
};

export const desactivateRecyclingStationsProfilesQR = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    withQR: false,
  });
};

export const activateRecyclingStationsProfilesNoQR = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    noQR: true,
  });
};

export const desactivateRecyclingStationsProfilesNoQR = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    noQR: false,
  });
};

export const activateRecyclingStationsProfilesPremiumClients = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    premiumClients: true,
  });
};

export const desactivateRecyclingStationsProfilesPremiumClients = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/profiles`).update({
    premiumClients: false,
  });
};

export const activateRecyclingStationsStores = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/stores`).update({
    active: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/stores`).update({
    deliveries: true,
  });
};

export const desactivateRecyclingStationsStores = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/stores`).update({
    active: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/stores`).update({
    deliveries: false,
  });
};

export const activateRecyclingStationsStoresDeliveries = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/stores`).update({
    deliveries: true,
  });
};

export const desactivateRecyclingStationsStoresDeliveries = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/stores`).update({
    deliveries: false,
  });
};

export const activateRecyclingStationsPams = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/pams`).update({
    active: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/pams`).update({
    deliveries: true,
  });
};

export const desactivateRecyclingStationsPams = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/pams`).update({
    active: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/pams`).update({
    deliveries: false,
  });
};

export const activateRecyclingStationsPamsDeliveries = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/pams`).update({
    deliveries: true,
  });
};

export const desactivateRecyclingStationsPamsDeliveries = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/pams`).update({
    deliveries: false,
  });
};

export const activateRecyclingStationsCenters = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/centers`).update({
    active: true,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/centers`).update({
    deliveries: true,
  });
};

export const desactivateRecyclingStationsCenters = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/centers`).update({
    active: false,
  });
  db.ref(`cityAdminSettings/${id}/recyclingStations/centers`).update({
    deliveries: false,
  });
};

export const activateRecyclingStationsCentersDeliveries = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/centers`).update({
    deliveries: true,
  });
};

export const desactivateRecyclingStationsCentersDeliveries = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations/centers`).update({
    deliveries: false,
  });
};

export const activateRecyclingStationsReturnability = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations`).update({
    returnability: true,
  });
};

export const desactivateRecyclingStationsReturnability = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations`).update({
    returnability: false,
  });
};

export const activateCleanPoints = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations`).update({
    cleanPoints: true,
  });
};

export const deactivateCleanPoints = id => {
  db.ref(`cityAdminSettings/${id}/recyclingStations`).update({
    cleanPoints: false,
  });
};

//recinegocios
export const activateReciNegocios = id => {
  db.ref(`cityAdminSettings/${id}/recinegocios`).update({ active: true });
  db.ref(`cityAdminSettings/${id}/recinegocios`).update({
    createNewReciNegocio: true,
  });
  db.ref(`cityAdminSettings/${id}/recinegocios`).update({ exchanges: true });
};

export const desactivateReciNegocios = id => {
  db.ref(`cityAdminSettings/${id}/recinegocios`).update({ active: false });
  db.ref(`cityAdminSettings/${id}/recinegocios`).update({
    createNewReciNegocio: false,
  });
  db.ref(`cityAdminSettings/${id}/recinegocios`).update({ exchanges: false });
};

export const activateReciNegociosCreateNew = id => {
  db.ref(`cityAdminSettings/${id}/recinegocios`).update({
    createNewReciNegocio: true,
  });
};

export const desactivateReciNegociosCreateNew = id => {
  db.ref(`cityAdminSettings/${id}/recinegocios`).update({
    createNewReciNegocio: false,
  });
};

export const activateReciNegociosExchanges = id => {
  db.ref(`cityAdminSettings/${id}/recinegocios`).update({ exchanges: true });
};

export const desactivateReciNegociosExchanges = id => {
  db.ref(`cityAdminSettings/${id}/recinegocios`).update({ exchanges: false });
};

//material deliveries
export const activateMaterialDeliveries = id => {
  db.ref(`cityAdminSettings/${id}/materialDeliveries`).update({ active: true });
  db.ref(`cityAdminSettings/${id}/materialDeliveries`).update({
    normalDeliveries: true,
  });
  db.ref(`cityAdminSettings/${id}/materialDeliveries`).update({
    deliveriesRequests: true,
  });
  db.ref(`cityAdminSettings/${id}/materialDeliveries`).update({
    subscriptions: true,
  });
};

export const desactivateMaterialDeliveries = id => {
  db.ref(`cityAdminSettings/${id}/materialDeliveries`).update({
    active: false,
  });
  db.ref(`cityAdminSettings/${id}/materialDeliveries`).update({
    normalDeliveries: false,
  });
  db.ref(`cityAdminSettings/${id}/materialDeliveries`).update({
    deliveriesRequests: false,
  });
  db.ref(`cityAdminSettings/${id}/materialDeliveries`).update({
    subscriptions: false,
  });
};

export const activateMaterialDeliveriesNormal = id => {
  db.ref(`cityAdminSettings/${id}/materialDeliveries`).update({
    normalDeliveries: true,
  });
};

export const desactivateMaterialDeliveriesNormal = id => {
  db.ref(`cityAdminSettings/${id}/materialDeliveries`).update({
    normalDeliveries: false,
  });
};

export const activateMaterialDeliveriesRequests = id => {
  db.ref(`cityAdminSettings/${id}/materialDeliveries`).update({
    deliveriesRequests: true,
  });
};

export const desactivateMaterialDeliveriesRequests = id => {
  db.ref(`cityAdminSettings/${id}/materialDeliveries`).update({
    deliveriesRequests: false,
  });
};

export const activateMaterialDeliveriesSubscriptions = id => {
  db.ref(`cityAdminSettings/${id}/materialDeliveries`).update({
    subscriptions: true,
  });
};

export const desactivateMaterialDeliveriesSubscriptions = id => {
  db.ref(`cityAdminSettings/${id}/materialDeliveries`).update({
    subscriptions: false,
  });
};

//experiments
export const activateExperiments = id => {
  db.ref(`cityAdminSettings/${id}/experiments`).update({ active: true });
  db.ref(`cityAdminSettings/${id}/experiments`).update({ groups1: true });
  db.ref(`cityAdminSettings/${id}/experiments`).update({ groups2: true });
  db.ref(`cityAdminSettings/${id}/experiments`).update({
    festivityGroups: true,
  });
  db.ref(`cityAdminSettings/${id}/experiments`).update({ comparison: true });
};

export const desactivateExperiments = id => {
  db.ref(`cityAdminSettings/${id}/experiments`).update({ active: false });
  db.ref(`cityAdminSettings/${id}/experiments`).update({ groups1: false });
  db.ref(`cityAdminSettings/${id}/experiments`).update({ groups2: false });
  db.ref(`cityAdminSettings/${id}/experiments`).update({
    festivityGroups: false,
  });
  db.ref(`cityAdminSettings/${id}/experiments`).update({ comparison: false });
};

export const activateExperimentsGroup1 = id => {
  db.ref(`cityAdminSettings/${id}/experiments`).update({ groups1: true });
};

export const desactivateExperimentsGroup1 = id => {
  db.ref(`cityAdminSettings/${id}/experiments`).update({ groups1: false });
};

export const activateExperimentsGroup2 = id => {
  db.ref(`cityAdminSettings/${id}/experiments`).update({ groups2: true });
};

export const desactivateExperimentsGroup2 = id => {
  db.ref(`cityAdminSettings/${id}/experiments`).update({ groups2: true });
};

export const activateExperimentsFestivityGroups = id => {
  db.ref(`cityAdminSettings/${id}/experiments`).update({
    festivityGroups: true,
  });
};

export const desactivateExperimentsFestivityGroups = id => {
  db.ref(`cityAdminSettings/${id}/experiments`).update({
    festivityGroups: false,
  });
};

export const activateExperimentsComparison = id => {
  db.ref(`cityAdminSettings/${id}/experiments`).update({ comparison: true });
};

export const desactivateExperimentsComparison = id => {
  db.ref(`cityAdminSettings/${id}/experiments`).update({ comparison: false });
};

//reports
export const activateReports = id => {
  db.ref(`cityAdminSettings/${id}/reports`).update({ active: true });
  db.ref(`cityAdminSettings/${id}/reports`).update({ mostRecyclerUser: true });
  db.ref(`cityAdminSettings/${id}/reports`).update({ mapReports: true });
  db.ref(`cityAdminSettings/${id}/reports`).update({ moreActiveUsers: true });
  db.ref(`cityAdminSettings/${id}/reports`).update({ habits: true });
  db.ref(`cityAdminSettings/${id}/reports`).update({ deliveries: true });
  db.ref(`cityAdminSettings/${id}/reports`).update({
    commerceDeliveries: true,
  });
};

export const desactivateReports = id => {
  db.ref(`cityAdminSettings/${id}/reports`).update({ active: false });
  db.ref(`cityAdminSettings/${id}/reports`).update({ mostRecyclerUser: false });
  db.ref(`cityAdminSettings/${id}/reports`).update({ mapReports: false });
  db.ref(`cityAdminSettings/${id}/reports`).update({ moreActiveUsers: false });
  db.ref(`cityAdminSettings/${id}/reports`).update({ habits: false });
  db.ref(`cityAdminSettings/${id}/reports`).update({ deliveries: false });
  db.ref(`cityAdminSettings/${id}/reports`).update({
    commerceDeliveries: false,
  });
};

export const activateReportsCommerceDeliveries = id => {
  db.ref(`cityAdminSettings/${id}/reports`).update({
    commerceDeliveries: true,
  });
};

export const deactivateReportsCommerceDeliveries = id => {
  db.ref(`cityAdminSettings/${id}/reports`).update({
    commerceDeliveries: false,
  });
};

export const activateReportsMostRecyclerUser = id => {
  db.ref(`cityAdminSettings/${id}/reports`).update({ mostRecyclerUser: true });
};

export const desactivateReportsMostRecyclerUser = id => {
  db.ref(`cityAdminSettings/${id}/reports`).update({ mostRecyclerUser: false });
};

export const activateReportsMap = id => {
  db.ref(`cityAdminSettings/${id}/reports`).update({ mapReports: true });
};

export const desactivateReportsMap = id => {
  db.ref(`cityAdminSettings/${id}/reports`).update({ mapReports: false });
};

export const activateReportsMoreActiveUsers = id => {
  db.ref(`cityAdminSettings/${id}/reports`).update({ moreActiveUsers: true });
};

export const desactivateReportsMoreActiveUsers = id => {
  db.ref(`cityAdminSettings/${id}/reports`).update({ moreActiveUsers: false });
};

export const activateReportsHabits = id => {
  db.ref(`cityAdminSettings/${id}/reports`).update({ habits: true });
};

export const desactivateReportsHabits = id => {
  db.ref(`cityAdminSettings/${id}/reports`).update({ habits: false });
};

export const activateReportsDeliveries = id => {
  db.ref(`cityAdminSettings/${id}/reports`).update({ deliveries: true });
};

export const desactivateReportsDeliveries = id => {
  db.ref(`cityAdminSettings/${id}/reports`).update({ deliveries: false });
};

//make route
export const activateRoute = id => {
  db.ref(`cityAdminSettings/${id}/route`).update({ active: true });
};

export const desactivateRoute = id => {
  db.ref(`cityAdminSettings/${id}/route`).update({ active: false });
};

//about
export const activateAbout = id => {
  db.ref(`cityAdminSettings/${id}/about`).update({ active: true });
};

export const desactivateAbout = id => {
  db.ref(`cityAdminSettings/${id}/about`).update({ active: false });
};
