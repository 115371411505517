import { db } from './index';

export const getTraceabilityById = id =>
  db.ref(`traceability/${id}`).once('value', userSnap => {
    return userSnap.val();
  });

export const createTraceability = (key, data) =>
  db.ref(`traceability/${key}`).set(data);

export const getTraceability = () =>
  db.ref('traceability').orderByChild('name');

export const updateTraceability = (id, data) =>
  db.ref(`traceability/${id}`).update(data);

export const activateTraceability = id =>
  db.ref(`traceability/${id}`).update({ active: true });

export const deactivateTraceability = id =>
  db.ref(`traceability/${id}`).update({ active: false });
