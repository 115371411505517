import React from 'react';
import { Icon, Menu } from 'antd';
import { Link } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import {
  QR_RECYCLERS,
} from '../constants/routes';

const CenterMenu = ({ state, handleClick, onOpenChange }) => {

  return (
    <Menu
      onClick={handleClick}
      openKeys={state.openKeys}
      mode="inline"
      theme="light"
      inlineCollapsed={state.collapsed}
      onOpenChange={onOpenChange}
      className="menu"
    >
      <Menu.Item key="home">
        <Link to="/">
          <Icon type="home" /> <span>Home</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="recycling-stations">
        <Link to={QR_RECYCLERS}>
          <span>
            <Icon type="qrcode" />
            <span>Reciclador/a con Qr</span>
          </span>
        </Link>
      </Menu.Item>

      <Menu.Item key="logout">
        <LogoutButton />
      </Menu.Item>
    </Menu>
  );
};

export default CenterMenu;
