/**
 * Created by chalosalvador on 17/01/2019.
 */
export const LOGIN = '/ingreso';
export const HOME = '/';
export const USERS = '/usuarios';
export const ASSOCIATIONS = '/asociaciones';
export const RECYCLERS = '/recicladores';
export const DELIVERIES = '/entregas';
export const EXCHANGES = '/canjes';
export const ABOUT = '/acerca-de';
export const MOSTRECYCLERUSER = '/usuario-mas-recicla';
export const MOSTACTIVEUSERS = '/usuarios-mas-activos';
export const DELIVERIESHEATMAP = '/mapa-de-entregas';
export const CREATEGROUPS = '/crear-grupos';
export const CREATEGROUPS2 = '/crear-grupos-2';
export const HABITS = '/use-habits';
export const REQUESTS = '/requests';
export const SUBSCRIPTIONS = '/subscriptions';
export const DELIVERIESFORRECYCLERS = '/entregas-recicladores';
export const EXPERIMENTS_COMPARISON = '/comparacion-experimentos';
export const MAKEROUTE = '/make-requests-route';
export const COMMERCE = '/comercios';
export const WHAT_ARE_WE_DOING = '/que-estamos-haciendo';
export const RECITIPS = '/recitips';
export const CAMPAIGNS_PARTNERS = '/campanas-aliados';
export const GRAPHICS_ANALYSIS = '/graficos-gerencial'
export const GRAPHICS_ANALYSIS_MATERIAL = '/graficos-materiales'
export const RECYCLERS_ANALYSIS = '/graficos-recicladores'
export const MANAGERS_TRACEABILITY = '/tabla-gestores'
export const EXPERIMENTS_PRODUCTS = '/experimentos-producto';
export const STORES_GRAPHICS='/graficos-tiendas'
export const ZERO_WASTE_STORES = "/tiendas-sin-residuos";
export const IGLOOS_PAMS = "/puntos-de-acopio-movil";
export const STORE_DELIVERIES = "/recolecciones-tiendas";
export const RETURNABILITY = "/retornabilidad";
export const CA_DELIVERIES = "/recolecciones-ca";
export const PAMS_DELIVERIES = "/recolecciones-pams";
export const GATHERING_CENTERS = "/centro-acopio";
export const RECYCLING_STATIONS = "/estaciones-reciclaje";
export const COLLECTORS = "/recolectores";
export const CITIES = "/ciudades";
export const NO_QR_PROFILES = "/perfiles-sin-qr";
export const INTERMEDIARIES = "/intermediarios";
export const TRANSFORMERS = "/transformadoras"
export const INTERMEDIARIES_TRANSACTIONS = "/transacciones-intermediarios";
export const CUSTOMERS = "/clientes-premium"
export const CREATE_USERS_PER_CITY = "/crear-usuarios"
export const ADMIN_CITIES = "/configuracion-ciudades"
export const QR_RECYCLERS = "/recicladores-qr"
export const CLEAN_POINTS = "/puntos-limpios"
export const IGLUS = "/iglus"
export const PICHAYBOTS = "/pichaybots"
export const COMMERCE_REPORTS = "/reporte-comercios"
export const COMMERCE_DELIVERIES = "/entregas-comercios"
export const STATIC_ROUTES = "/rutas-estaticas"
export const CAMPAIGNS = "/campañas"
export const STATIC_ROUTES_DELIVERIES = "/entregas-rutas-estaticas"
export const CAMPAIGNS_DELIVERIES = "/entregas-campañas"
export const TRACEABILITY_USERS = "/usuarios-trazabilidad"
export const TRACEABILITY_COLLECTORS_TRANSACTIONS = "/transacciones-recolectores-trazabilidad"
export const TRACEABILITY_IGLOOS_PAMS_TRANSACTIONS = "/transacciones-estaciones-reciclaje-trazabilidad"
export const TRACEABILITY_RECICLATONES_TRANSACTIONS = "/transacciones-reciclatones-trazabilidad"
export const STATION_WEIGHTS = "/pesos-estaciones"
export const STORES_STATIONS_REQUESTS = "/solicitudes-tiendas-estaciones"
export const TRACEABILITY_CENTERS_TRANSACTIONS = "/transacciones-centros-acopio-trazabilidad"
export const FIESTA_CERCA_COUPONS = "/cupones-fiesta-cerca"
export const ACTIVE_USERS = "/usuarios-activos"
export const CONTAINERS = "/contenedores"
export const NO_QR_DELIVERIES = "/entregas-no-qr"
export const TSR_USERS = "/usuarios-tsr"
export const FILTER_RECYCLERS = "/recicladores-filtrados"
export const FILTER_USERS = "/usuarios-ciudad-filtrados"
export const STORES_DELIVERIES_REQUESTS = "/solicitudes-recolecciones-tiendas"
export const SUPER_ADMINISTRATOR_USERS_PROFILES = "/perfiles-usuarios-super-administrador"
export const RECYCLING_STATIONS_WEIGHTS_PAGE = "/pesos-estaciones-reciclaje"
export const RECICLATON_WEIGHTS_PAGE = "/pesos-reciclaton"
export const RECIAPP_WEIGHTS_PAGE = "/pesos-reciapp"
export const PREMIUM_WEIGHTS_PAGE = "/pesos-premium"
export const STORES_WEIGHTS_PAGE = "/pesos-tiendas"
export const RECIRCULAR_USERS = "/usuarios-recircular"
export const PREMIUM_USERS= "/usuarios-premium"
export const STORES_ANALYTICS = "/analiticas-tiendas"
export const PREMIUM_CLIENTS_DELIVERIES = "/entregas-clientes"
export const RECYCLING_STATIONS_WEIGHTS_TRACEABILITY_PAGE = "/pesos-estaciones-trazabilidad"
export const CAMPAIGNS_PARTNERS_DELIVERIES = "/entregas-campanas-aliados"
