/**
 * Created by chalosalvador on 2019-01-26
 */

import { db } from './index';

export const getUser = id =>
  db.ref(`user/${id}`).once('value', userSnap => {
    return userSnap.val();
  });

export const getUsers = () => db.ref('user').orderByChild('lastname');

export const getUserKey = () => db.ref('/user').push().key;

export const setUser = (user, id) => db.ref(`user/${id}`).set(user);

export const updateUser = (id, data) => {
  const updates = {};
  updates[`user/${id}`] = data;
  return db.ref().update(updates);
};

export const updateCommerceUser = (id, data) =>
  db.ref(`user/${id}`).update(data);

export const getCommerceUsers = () =>
  db.ref('user').orderByChild('commerceUser').equalTo(true);

export const updateUserTSRData = id =>
  db
    .ref(`user/${id}/points`)
    .update({ storeTmpBottles: 0, storeTmpDeliveries: 0 });

export const createAdminUserProfile = (id, data) =>
  db.ref(`user/${id}/profile`).set(data);
export const updateAdminUserProfile = (id, data) =>
  db.ref(`user/${id}/profile`).update(data);

export const updateUserTSRVerifiedDeliveriesData = (
  id,
  tmpBottles,
  totalBottles,
  tmpDeliveries,
  totalDeliveries,
  traceabilityPoints,
  totalPoints,
) =>
  db.ref(`user/${id}/points`).update({
    storeTmpBottles: tmpBottles,
    storeTotalBottles: totalBottles,
    storeTmpDeliveries: tmpDeliveries + 1,
    storeTotalDeliveries: totalDeliveries + 1,
    pointsTraceability: traceabilityPoints + 300,
    total: totalPoints + 300,
  });

export const getTSRUserData = id => db.ref(`user/${id}`).orderByChild('name');

// For Recircular Users
export const createUserData = data => db.ref('user/').push(data);
export const updateUserData = (id, data) => db.ref(`user/${id}`).update(data);
export const getRecircularUsers = () =>
  db.ref('user').orderByChild('isRecircularAdmin').equalTo(true);

  export const getPremiumUsers = () =>
  db.ref('user').orderByChild('isPremiumClient').equalTo(true);  

// Traceability users

export const getTraceabilityUserData = id =>
  db.ref(`traceability/${id}`).once('value', userSnap => {
    return userSnap.val();
  });