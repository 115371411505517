import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from '../containers/NotFoundPage';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Loadable from 'react-loadable';
import Loading from '../components/Loading';
import {
  ABOUT,
  DELIVERIES,
  EXCHANGES,
  HOME,
  LOGIN,
  RECYCLERS,
  USERS,
  MOSTRECYCLERUSER,
  CREATEGROUPS,
  DELIVERIESHEATMAP,
  HABITS,
  REQUESTS,
  MOSTACTIVEUSERS,
  DELIVERIESFORRECYCLERS,
  EXPERIMENTS_COMPARISON,
  MAKEROUTE,
  CREATEGROUPS2,
  COMMERCE,
  WHAT_ARE_WE_DOING,
  CAMPAIGNS_PARTNERS,
  GRAPHICS_ANALYSIS,
  EXPERIMENTS_PRODUCTS,
  SUBSCRIPTIONS,
  ZERO_WASTE_STORES,
  STORE_DELIVERIES,
  IGLOOS_PAMS,
  RETURNABILITY,
  CA_DELIVERIES,
  PAMS_DELIVERIES,
  GATHERING_CENTERS,
  RECYCLING_STATIONS,
  ASSOCIATIONS,
  COLLECTORS,
  CITIES,
  NO_QR_PROFILES,
  INTERMEDIARIES,
  TRANSFORMERS,
  INTERMEDIARIES_TRANSACTIONS,
  CUSTOMERS,
  CREATE_USERS_PER_CITY,
  ADMIN_CITIES,
  QR_RECYCLERS,
  CLEAN_POINTS,
  IGLUS, PICHAYBOTS,
  COMMERCE_REPORTS,
  COMMERCE_DELIVERIES,
  STATIC_ROUTES,
  CAMPAIGNS, STATIC_ROUTES_DELIVERIES,
  CAMPAIGNS_DELIVERIES,
  TRACEABILITY_USERS,
  TRACEABILITY_COLLECTORS_TRANSACTIONS,
  TRACEABILITY_IGLOOS_PAMS_TRANSACTIONS,
  STATION_WEIGHTS, STORES_STATIONS_REQUESTS,
  TRACEABILITY_CENTERS_TRANSACTIONS, FIESTA_CERCA_COUPONS,
  ACTIVE_USERS, CONTAINERS, NO_QR_DELIVERIES, TSR_USERS, FILTER_RECYCLERS,
  FILTER_USERS, STORES_DELIVERIES_REQUESTS, SUPER_ADMINISTRATOR_USERS_PROFILES,
  RECYCLING_STATIONS_WEIGHTS_PAGE, RECICLATON_WEIGHTS_PAGE,
  RECIAPP_WEIGHTS_PAGE, PREMIUM_WEIGHTS_PAGE, STORES_WEIGHTS_PAGE, RECIRCULAR_USERS,
  STORES_ANALYTICS, PREMIUM_CLIENTS_DELIVERIES, GRAPHICS_ANALYSIS_MATERIAL,
  MANAGERS_TRACEABILITY, STORES_GRAPHICS, RECYCLERS_ANALYSIS,
  RECYCLING_STATIONS_WEIGHTS_TRACEABILITY_PAGE,TRACEABILITY_RECICLATONES_TRANSACTIONS,
  PREMIUM_USERS, RECITIPS, CAMPAIGNS_PARTNERS_DELIVERIES
} from "../constants/routes";

const AsyncLogin = Loadable({
  loader: () => import('../containers/LoginPage'),
  loading: Loading,
});

const AsyncHome = Loadable({
  loader: () => import('../containers/HomePage'),
  loading: Loading,
});

const AsyncUsers = Loadable({
  loader: () => import('../containers/UsersPage'),
  loading: Loading,
});

const AsyncAssociations = Loadable({
  loader: () => import('../containers/AssociationsPage'),
  loading: Loading,
});

const AsyncCityOptions = Loadable({
  loader: () => import('../containers/CityOptionsTablePage'),
  loading: Loading,
});

const AsyncCampaigns = Loadable({
  loader: () => import('../containers/CampaignsTablePage'),
  loading: Loading,
});

const AsyncCampaignsDeliveries = Loadable({
  loader: () => import('../containers/CampaignsDeliveriesPage'),
  loading: Loading,
});

const AsyncRecyclers = Loadable({
  loader: () => import('../containers/RecyclersPage'),
  loading: Loading,
});

const AsyncQrRecyclers = Loadable({
  loader: () => import('../containers/QRRecyclersTablePage'),
  loading: Loading,
});

const AsyncDeliveries = Loadable({
  loader: () => import('../containers/DeliveriesPage'),
  loading: Loading,
});

const AsyncAbout = Loadable({
  loader: () => import('../containers/AboutPage'),
  loading: Loading,
});

const AsyncExchanges = Loadable({
  loader: () => import('../containers/ExchangesPage'),
  loading: Loading,
});
const AsyncMostRecyclerUser = Loadable({
  loader: () => import('../containers/MostRecyclerUserPage'),
  loading: Loading,
});

const AsyncUseHabits = Loadable({
  loader: () => import('../containers/UseHabitsPage'),
  loading: Loading,
});

const AsyncRequests = Loadable({
  loader: () => import('../containers/DeliveryRequestsPage'),
  loading: Loading,
});

const AsyncSubscriptions = Loadable({
  loader: () => import('../containers/SubscriptionsTablePage'),
  loading: Loading,
});

const AsyncDeliveriesHeatMap = Loadable({
  loader: () => import('../containers/DeliveriesHeatMapPage'),
  loading: Loading,
});

const AsyncCreateGroups = Loadable({
  loader: () => import('../containers/CreateGroupsPage'),
  loading: Loading,
});
const AsyncCreateGroups2 = Loadable({
  loader: () => import('../containers/CreateGroupsPage2'),
  loading: Loading,
});
const AsyncMostActiveUsers = Loadable({
  loader: () => import('../containers/MostActiveUsersPage'),
  loading: Loading,
});
const AsyncDeliveriesForRecyclers = Loadable({
  loader: () => import('../containers/DeliveriesForRecyclersPage'),
  loading: Loading,
});
const AsyncMakeRoute = Loadable({
  loader: () => import('../containers/RequestsPlannedToMakeRoutePage'),
  loading: Loading,
});
const AsyncExperimentsComparison = Loadable({
  loader: () => import('../containers/ExperimentsComparison'),
  loading: Loading,
});
const AsyncExperimentsProduct = Loadable({
  loader: () => import('../containers/ExperimentProductPage'),
  loading: Loading,
});

const AsyncRecyclingStations = Loadable({
  loader: () => import('../containers/RecyclingStationProfilesPage'),
  loading: Loading,
});

const AsyncNoQRProfiles = Loadable({
  loader: () => import('../containers/NoQRProfilesPage'),
  loading: Loading,
});

const AsyncCustomers = Loadable({
  loader: () => import('../containers/CustomersTablePage'),
  loading: Loading,
});

const AsyncZeroWasteStores = Loadable({
  loader: () => import('../containers/StorekeepersPage'),
  loading: Loading,
});

const AsyncIgloosPams = Loadable({
  loader: () => import('../containers/IgloosPamsPage'),
  loading: Loading,
});

const AsyncGatheringCenters = Loadable({
  loader: () => import('../containers/GatheringCenterPage'),
  loading: Loading,
});

const AsyncCollectors = Loadable({
  loader: () => import('../containers/CollectorsTablePage'),
  loading: Loading,
});

const AsyncIntermediaries = Loadable({
  loader: () => import('../containers/IntermediariesTablePage'),
  loading: Loading,
});

const AsyncStoreDeliveries = Loadable({
  loader: () => import('../containers/StoreDeliveriesTablePage'),
  loading: Loading,
});

const AsyncReturnability = Loadable({
  loader: () => import('../containers/ReturnabilityTablePage'),
  loading: Loading,
});

const AsyncCADeliveries = Loadable({
  loader: () => import('../containers/CADeliveriesTablePage'),
  loading: Loading,
});

const AsyncPamsDeliveries = Loadable({
  loader: () => import('../containers/PamsDeliveriesTablePage'),
  loading: Loading,
});

const AsyncCommerce = Loadable({
  loader: () => import('../containers/CommerceTablePage'),
  loading: Loading,
});

const AsyncWhatAreWeDoing = Loadable({
  loader: () => import('../containers/WhatAreWeDoingPage'),
  loading: Loading,
});

const AsyncGraphicsAnalysis = Loadable({
  loader: () => import("../containers/GraphicsWeightsPage"),
  loading: Loading
});

const AsyncGraphicsAnalysisMaterial = Loadable({
  loader: () => import("../containers/GraphicsMaterialsWeightsPage"),
  loading: Loading
});

const AsyncManagersTraceability = Loadable({
  loader: () => import("../containers/ManagersTraceabilityPage"),
  loading: Loading
});

const AsyncStoresGraphics = Loadable({
  loader: () => import("../containers/StoresGraphicsPage"),
  loading: Loading
});

const AsyncRecyclersAnalysis = Loadable({
  loader: () => import("../containers/GraphicsRecyclersPage"),
  loading: Loading
});

const AsyncTransformer = Loadable({
  loader: () => import('../containers/TransformerPage'),
  loading: Loading,
});

const AsyncIntermediariesTransactions = Loadable({
  loader: () => import('../containers/IntermediariesTransactionsTablePage'),
  loading: Loading,
});

const AsyncCreateUsersPerCity = Loadable({
  loader: () => import('../containers/CreateUsersPerCityPage'),
  loading: Loading,
});

const AsyncAdminCities = Loadable({
  loader: () => import('../containers/CityAdminSettingsPage'),
  loading: Loading,
});

const AsyncCleanPoints = Loadable({
  loader: () => import('../containers/CleanPointsPage'),
  loading: Loading,
});

const AsyncIglus = Loadable({
  loader: () => import('../containers/IglusTablePage'),
  loading: Loading,
});

const AsyncPichayBots = Loadable({
  loader: () => import('../containers/PichayBotsTablePage'),
  loading: Loading,
});

const AsyncCommerceReport = Loadable({
  loader: () => import('../containers/CommerceDeliveriesReportsPage'),
  loading: Loading,
});

const AsyncCommerceDeliveries = Loadable({
  loader: () => import('../containers/CommerceUsersDeliveriesPage'),
  loading: Loading,
});

const AsyncStaticRoutes = Loadable({
  loader: () => import('../containers/RoutesTablePage'),
  loading: Loading,
});

const AsyncStaticRoutesDeliveries = Loadable({
  loader: () => import('../containers/StaticRoutesDeliveriesPage'),
  loading: Loading,
});

//Traceability
const AsyncTraceabilityUsers = Loadable({
  loader: () => import('../containers/TraceabilityUsersPage'),
  loading: Loading,
});

const AsyncTraceabilityCollectorsTransactions = Loadable({
  loader: () => import('../containers/TraceabilityCollectorTransactionsPage'),
  loading: Loading,
});

const AsyncTraceabilityIglooPamsTransactions = Loadable({
  loader: () => import('../containers/TraceabilityIgloosPamsTransactionsPage'),
  loading: Loading,
});

const AsyncTraceabilityCenterTransactions = Loadable({
  loader: () => import('../containers/TraceabilityCenterTransactionsPage'),
  loading: Loading,
});

const AsyncStationWeights = Loadable({
  loader: () => import('../containers/StationWeightsPage'),
  loading: Loading,
});

const AsyncStoreStationRequests = Loadable({
  loader: () => import('../containers/StoresAndStationsRequestsPage'),
  loading: Loading,
});

const AsyncFiestaCercaCoupons = Loadable({
  loader: () => import('../containers/FiestaCercaCouponsPage'),
  loading: Loading,
});

const AsyncActiveUsers = Loadable({
  loader: () => import('../containers/ActiveUsersModalPage'),
  loading: Loading,
});

const AsyncContainers = Loadable({
  loader: () => import('../containers/ContainersPage'),
  loading: Loading,
});

const AsyncNoQRDeliveries = Loadable({
  loader: () => import('../containers/NoQRDeliveriesPage'),
  loading: Loading,
});

const AsyncTSRUsers = Loadable({
  loader: () => import('../containers/TSRUsersDeliveriesPage'),
  loading: Loading,
});

const AsyncFilterRecyclers = Loadable({
  loader: () => import('../containers/FilterRecyclers'),
  loading: Loading,
});

const AsyncFilterUsers = Loadable({
  loader: () => import('../containers/FilteredUsersPerCityPage'),
  loading: Loading,
});

const AsyncStoresDeliveriesRequests = Loadable({
  loader: () => import('../containers/StoresNewDeliveryRequestPage'),
  loading: Loading,
});

const AsyncSuperAdministratorUsersProfiles = Loadable({
  loader: () => import('../containers/SuperAdministratorUsersProfiles'),
  loading: Loading,
});

const AsyncRecyclingStationWeights = Loadable({
  loader: () => import('../containers/RecyclingStationsWeightsPage'),
  loading: Loading,
});

const AsyncReciclatonWeights = Loadable({
  loader: () => import('../containers/ReciclatonWeightsPage'),
  loading: Loading,
});

const AsyncReciAppWeights = Loadable({
  loader: () => import('../containers/ReciAppWeightsPage'),
  loading: Loading,
});

const AsyncPremiumWeights = Loadable({
  loader: () => import('../containers/PremiumWeightsPage'),
  loading: Loading,
});

const AsyncStoresWeights = Loadable({
  loader: () => import('../containers/TotalStoresWeightsPage'),
  loading: Loading,
});

const AsyncRecyclingStationTraceabilityWeights = Loadable({
  loader: () => import('../containers/RecyclingStationsWeightsTraceabilityPage'),
  loading: Loading,
});

const AsyncReciclatonTraceabilityWeights = Loadable({
  loader: () => import('../containers/ReciclatonWeightsTraceabilityPage'),
  loading: Loading,
});

const AsyncRecircularUsers = Loadable({
  loader: () => import('../containers/RecircularUsersPage'),
  loading: Loading,
});

const AsyncPremiumUsers = Loadable({
  loader: () => import('../containers/PremiumClientsUsersPage'),
  loading: Loading,
});

const AsyncStoresAnalytics = Loadable({
  loader: () => import('../containers/StoresAnalyticsPage'),
  loading: Loading,
});

//Clientes premium
const AsyncPremiumClientsDeliveries = Loadable({
  loader: () => import('../containers/PremiumClientDeliveriesPage'),
  loading: Loading,
});

const AsyncCampaignsPartners = Loadable({
  loader: () => import('../containers/CampaignsPartnersTablePage'),
  loading: Loading,
});

const AsyncRecitips = Loadable({
  loader: () => import('../containers/RecitipsPage'),
  loading: Loading,
});

const AsyncCampaignsPartnersDeliveries = Loadable({
  loader: () => import('../containers/CampaignPartnersDeliveryPage'),
  loading: Loading,
});

class AppRouter extends Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.props.collapsed === nextProps.collapse; // avoids re-rendering children when menu is toggled
  }

  render() {
    return (
      <Switch>
        <PublicRoute path={LOGIN} component={AsyncLogin} />
        <PrivateRoute exact={true} path={HOME} component={AsyncHome} />
        <PrivateRoute path={USERS} component={AsyncUsers} />
        <PrivateRoute
          path={CREATE_USERS_PER_CITY}
          component={AsyncCreateUsersPerCity}
        />
        <PrivateRoute
          path={TRACEABILITY_USERS}
          component={AsyncTraceabilityUsers}
        />
        <PrivateRoute path={TRACEABILITY_COLLECTORS_TRANSACTIONS}
          component={AsyncTraceabilityCollectorsTransactions} />
        <PrivateRoute path={TRACEABILITY_IGLOOS_PAMS_TRANSACTIONS}
          component={AsyncTraceabilityIglooPamsTransactions} />
        <PrivateRoute path={TRACEABILITY_CENTERS_TRANSACTIONS} component={AsyncTraceabilityCenterTransactions} />
        <PrivateRoute path={ASSOCIATIONS} component={AsyncAssociations} />
        <PrivateRoute path={RECYCLERS} component={AsyncRecyclers} />
        <PrivateRoute path={QR_RECYCLERS} component={AsyncQrRecyclers} />
        <PrivateRoute path={DELIVERIES} component={AsyncDeliveries} />
        <PrivateRoute
          path={ZERO_WASTE_STORES}
          component={AsyncZeroWasteStores}
        />
        <PrivateRoute path={CITIES} component={AsyncCityOptions} />
        <PrivateRoute path={CAMPAIGNS} component={AsyncCampaigns} />
        <PrivateRoute path={CAMPAIGNS_DELIVERIES} component={AsyncCampaignsDeliveries} />
        <PrivateRoute
          path={RECYCLING_STATIONS}
          component={AsyncRecyclingStations}
        />
        <PrivateRoute path={NO_QR_PROFILES} component={AsyncNoQRProfiles} />
        <PrivateRoute path={NO_QR_DELIVERIES} component={AsyncNoQRDeliveries} />
        <PrivateRoute path={CUSTOMERS} component={AsyncCustomers} />
        <PrivateRoute path={IGLOOS_PAMS} component={AsyncIgloosPams} />
        <PrivateRoute
          path={GATHERING_CENTERS}
          component={AsyncGatheringCenters}
        />
        <PrivateRoute
          path={STORE_DELIVERIES}
          component={AsyncStoreDeliveries}
        />
        <PrivateRoute path={COLLECTORS} component={AsyncCollectors} />
        <PrivateRoute path={INTERMEDIARIES} component={AsyncIntermediaries} />
        <PrivateRoute
          path={INTERMEDIARIES_TRANSACTIONS}
          component={AsyncIntermediariesTransactions}
        />
        <PrivateRoute path={TRANSFORMERS} component={AsyncTransformer} />
        <PrivateRoute path={RETURNABILITY} component={AsyncReturnability} />
        <PrivateRoute path={CA_DELIVERIES} component={AsyncCADeliveries} />
        <PrivateRoute path={PAMS_DELIVERIES} component={AsyncPamsDeliveries} />
        <PrivateRoute path={EXCHANGES} component={AsyncExchanges} />
        <PrivateRoute path={ABOUT} component={AsyncAbout} />
        <PrivateRoute
          path={MOSTRECYCLERUSER}
          component={AsyncMostRecyclerUser}
        />
        <PrivateRoute path={COMMERCE_REPORTS} component={AsyncCommerceReport} />
        <PrivateRoute path={HABITS} component={AsyncUseHabits} />
        <PrivateRoute path={REQUESTS} component={AsyncRequests} />
        <PrivateRoute path={SUBSCRIPTIONS} component={AsyncSubscriptions} />
        <PrivateRoute
          path={DELIVERIESHEATMAP}
          component={AsyncDeliveriesHeatMap}
        />
        <PrivateRoute path={CREATEGROUPS} component={AsyncCreateGroups} />
        <PrivateRoute path={CREATEGROUPS2} component={AsyncCreateGroups2} />
        <PrivateRoute path={MOSTACTIVEUSERS} component={AsyncMostActiveUsers} />
        <PrivateRoute
          path={DELIVERIESFORRECYCLERS}
          component={AsyncDeliveriesForRecyclers}
        />
        <PrivateRoute path={MAKEROUTE} component={AsyncMakeRoute} />
        <PrivateRoute path={STATIC_ROUTES} component={AsyncStaticRoutes} />
        <PrivateRoute path={STATIC_ROUTES_DELIVERIES} component={AsyncStaticRoutesDeliveries} />
        <PrivateRoute
          path={EXPERIMENTS_COMPARISON}
          component={AsyncExperimentsComparison}
        />
        <PrivateRoute path={COMMERCE} component={AsyncCommerce} />
        <PrivateRoute path={WHAT_ARE_WE_DOING} component={AsyncWhatAreWeDoing} />
        <PrivateRoute path={CAMPAIGNS_PARTNERS} component={AsyncCampaignsPartners} />
        <PrivateRoute path={GRAPHICS_ANALYSIS} component={AsyncGraphicsAnalysis} />
        <PrivateRoute path={GRAPHICS_ANALYSIS_MATERIAL} component={AsyncGraphicsAnalysisMaterial} />
        <PrivateRoute path={MANAGERS_TRACEABILITY} component={AsyncManagersTraceability} />
        <PrivateRoute path={STORES_GRAPHICS} component={AsyncStoresGraphics} />
        <PrivateRoute path={RECYCLERS_ANALYSIS} component={AsyncRecyclersAnalysis} />
        <PrivateRoute
          path={EXPERIMENTS_PRODUCTS}
          component={AsyncExperimentsProduct}
        />
        <PrivateRoute path={ADMIN_CITIES} component={AsyncAdminCities} />
        <PrivateRoute path={CLEAN_POINTS} component={AsyncCleanPoints} />
        <PrivateRoute path={IGLUS} component={AsyncIglus} />
        <PrivateRoute path={PICHAYBOTS} component={AsyncPichayBots} />
        <PrivateRoute path={COMMERCE_DELIVERIES} component={AsyncCommerceDeliveries} />
        <PrivateRoute path={STATION_WEIGHTS} component={AsyncStationWeights} />
        <PrivateRoute path={STORES_STATIONS_REQUESTS} component={AsyncStoreStationRequests} />
        <PrivateRoute path={FIESTA_CERCA_COUPONS} component={AsyncFiestaCercaCoupons} />
        <PrivateRoute path={ACTIVE_USERS} component={AsyncActiveUsers} />
        <PrivateRoute path={CONTAINERS} component={AsyncContainers} />
        <PrivateRoute path={TSR_USERS} component={AsyncTSRUsers} />
        <PrivateRoute path={FILTER_RECYCLERS} component={AsyncFilterRecyclers} />
        <PrivateRoute path={FILTER_USERS} component={AsyncFilterUsers} />
        <PrivateRoute path={STORES_DELIVERIES_REQUESTS} component={AsyncStoresDeliveriesRequests} />
        <PrivateRoute path={SUPER_ADMINISTRATOR_USERS_PROFILES} component={AsyncSuperAdministratorUsersProfiles} />
        <PrivateRoute path={RECYCLING_STATIONS_WEIGHTS_PAGE} component={AsyncRecyclingStationWeights} />
        <PrivateRoute path={RECICLATON_WEIGHTS_PAGE} component={AsyncReciclatonWeights} />
        <PrivateRoute path={RECIAPP_WEIGHTS_PAGE} component={AsyncReciAppWeights} />
        <PrivateRoute path={PREMIUM_WEIGHTS_PAGE} component={AsyncPremiumWeights} />
        <PrivateRoute path={RECYCLING_STATIONS_WEIGHTS_TRACEABILITY_PAGE} component={AsyncRecyclingStationTraceabilityWeights} />
        <PrivateRoute path={TRACEABILITY_RECICLATONES_TRANSACTIONS} component={AsyncReciclatonTraceabilityWeights} />
        <PrivateRoute path={STORES_WEIGHTS_PAGE} component={AsyncStoresWeights} />
        <PrivateRoute path={RECIRCULAR_USERS} component={AsyncRecircularUsers} />
        <PrivateRoute path={PREMIUM_USERS} component={AsyncPremiumUsers} />
        <PrivateRoute path={STORES_ANALYTICS} component={AsyncStoresAnalytics} />
        <PrivateRoute path={PREMIUM_CLIENTS_DELIVERIES} component={AsyncPremiumClientsDeliveries} />
        <PrivateRoute path={RECITIPS} component={AsyncRecitips} />
        <PrivateRoute path={CAMPAIGNS_PARTNERS_DELIVERIES} component={AsyncCampaignsPartnersDeliveries} />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

export default AppRouter;
