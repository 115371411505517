import React, { useState, useEffect } from 'react';
import { auth } from '../firebase/index';
import { Icon, Menu } from 'antd';
import { Link } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import { getTraceabilityById } from '../firebase/traceabilityUsers';
import { TRACEABILITY_IGLOOS_PAMS_TRANSACTIONS, TRACEABILITY_RECICLATONES_TRANSACTIONS } from '../constants/routes';
import { RECYCLING_STATIONS_WEIGHTS_TRACEABILITY_PAGE } from '../constants/routes';

const TraceabilityMenu = ({ state, handleClick, onOpenChange }) => {
  const uid = auth.currentUser.uid;
  const [traceabilityUser, setTraceabilityUser] = useState({});

  useEffect(() => {
    (async () => {
      const traceabilityUserData = await getTraceabilityById(uid);
      setTraceabilityUser(traceabilityUserData.val());
    })();
  }, []);

  return (
    <Menu
      onClick={handleClick}
      openKeys={state.openKeys}
      mode="inline"
      theme="light"
      inlineCollapsed={state.collapsed}
      onOpenChange={onOpenChange}
      className="menu"
    >
      <Menu.Item key="home">
        <Link to="/">
          <Icon type="home" /> <span>Home</span>
        </Link>
      </Menu.Item>

      {traceabilityUser.stationsView && (
        <Menu.Item key="recycling-stations">
          <Link to={TRACEABILITY_IGLOOS_PAMS_TRANSACTIONS}>
            <span>
              <Icon type="table" />
              <span>Estaciones de reciclaje</span>
            </span>
          </Link>
        </Menu.Item>
      )}

      {traceabilityUser.storeKeepers && (
        <Menu.Item key="storeKeepers">
          <Link to="/">
            <span>
              <Icon type="shopping-cart" />
              <span>Tiendas Sin Residuos</span>
            </span>
          </Link>
        </Menu.Item>
      )}

      <Menu.Item key="weight-recycling-stations">
        <Link to={RECYCLING_STATIONS_WEIGHTS_TRACEABILITY_PAGE}>
          <span>
            <Icon type="gold" />
            <span>Pesos de estaciones de reciclaje</span>
          </span>
        </Link>
      </Menu.Item>


      {traceabilityUser.reciclatonsWeightsView && (
        <Menu.Item key="weight-reciclatones">
          <Link to={TRACEABILITY_RECICLATONES_TRANSACTIONS}>
            <span>
              <Icon type="gold" />
              <span>Pesos de reciclatones</span>
            </span>
          </Link>
        </Menu.Item>
      )}


      {/* <Menu.Item key="cegam">
        <Link to={TRACEABILITY_CENTERS_TRANSACTIONS}>
          <span>
            <Icon type="reconciliation" />
            <span>Centros de Acopio</span>
          </span>
        </Link>
      </Menu.Item>

      <Menu.Item key="collectors">
        <Link to={TRACEABILITY_COLLECTORS_TRANSACTIONS}>
          <span>
            <Icon type="car" />
            <span>Recolectores</span>
          </span>
        </Link>
      </Menu.Item> */}

      <Menu.Item key="logout">
        <LogoutButton />
      </Menu.Item>
    </Menu>
  );
};

export default TraceabilityMenu;
