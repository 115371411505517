import React from "react";
import {Icon, Menu} from "antd";
import {Link} from "react-router-dom";
import {
    ABOUT,
    DELIVERIES,
    EXCHANGES,
    RECYCLERS,
    USERS,
    MOSTRECYCLERUSER,
    CREATEGROUPS,
    DELIVERIESHEATMAP,
    HABITS,
    REQUESTS,
    MOSTACTIVEUSERS,
    DELIVERIESFORRECYCLERS,
    EXPERIMENTS_COMPARISON,
    MAKEROUTE,
    CREATEGROUPS2,
    COMMERCE,
    WHAT_ARE_WE_DOING,
    GRAPHICS_ANALYSIS,
    EXPERIMENTS_PRODUCTS,
    SUBSCRIPTIONS,
    STORE_DELIVERIES,
    RETURNABILITY,
    NO_QR_PROFILES,
    CA_DELIVERIES,
    PAMS_DELIVERIES,
    RECYCLING_STATIONS,
    ASSOCIATIONS,
    COLLECTORS,
    CITIES,
    INTERMEDIARIES,
    TRANSFORMERS,
    INTERMEDIARIES_TRANSACTIONS,
    CUSTOMERS,
    CREATE_USERS_PER_CITY,
    ADMIN_CITIES,
    QR_RECYCLERS,
    CLEAN_POINTS, IGLUS, PICHAYBOTS,
    COMMERCE_REPORTS, COMMERCE_DELIVERIES, STATIC_ROUTES, CAMPAIGNS, STATIC_ROUTES_DELIVERIES,
    CAMPAIGNS_DELIVERIES,
    TRACEABILITY_USERS, TRACEABILITY_COLLECTORS_TRANSACTIONS,
    STATION_WEIGHTS, STORES_STATIONS_REQUESTS, FIESTA_CERCA_COUPONS, ACTIVE_USERS,
    CONTAINERS, NO_QR_DELIVERIES, TSR_USERS, FILTER_RECYCLERS, FILTER_USERS, STORES_DELIVERIES_REQUESTS,
    SUPER_ADMINISTRATOR_USERS_PROFILES, RECYCLING_STATIONS_WEIGHTS_PAGE,
    RECICLATON_WEIGHTS_PAGE, RECIAPP_WEIGHTS_PAGE, PREMIUM_WEIGHTS_PAGE,
    STORES_WEIGHTS_PAGE, RECIRCULAR_USERS, STORES_ANALYTICS, PREMIUM_CLIENTS_DELIVERIES, GRAPHICS_ANALYSIS_MATERIAL, MANAGERS_TRACEABILITY, STORES_GRAPHICS, RECYCLERS_ANALYSIS, PREMIUM_USERS, CAMPAIGNS_PARTNERS,
    RECITIPS,
    CAMPAIGNS_PARTNERS_DELIVERIES
} from "../constants/routes";
import LogoutButton from "./LogoutButton";


const {SubMenu} = Menu;

const PrincipalMenu = ({state, handleClick, onOpenChange}) => {

    console.log("menu data", state);

    return (

        <>
            {state.cocaCola === true ?


            <Menu
                onClick={handleClick}
                openKeys={state.openKeys}
                mode="inline"
                theme="light"
                inlineCollapsed={state.collapsed}
                onOpenChange={onOpenChange}
                className="menu"
            >
                <Menu.Item key="home">
                    <Link to="/">
                        <Icon type="home"/> <span>Home</span>
                    </Link>
                </Menu.Item>

                <SubMenu
                    key="main-stores"
                    title={
                        <span>
                             <Icon type="bank"/>
                             <span>Mi Tienda sin Residuos</span>
                         </span>
                    }
                >

                        <Menu.Item>
                            <Link to={STORES_ANALYTICS}>
                                <Icon type="stock"/>
                                <span>Análisis de datos</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item>
                            <Link to={STORES_WEIGHTS_PAGE}>
                                <Icon type="gold"/>
                                <span>Pesos</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="stores_deliveries">
                            <Link to={STORE_DELIVERIES}>
                                          <span>
                                            <Icon type="team"/>
                                            <span>Entregas de material</span>
                                          </span>
                            </Link>
                        </Menu.Item>

                            <Menu.Item>
                                <Link to={STORES_DELIVERIES_REQUESTS}>
                                    <Icon type="pic-right"/>
                                    <span>Recolecciones Tiendas</span>
                                </Link>
                            </Menu.Item>

                            <Menu.Item>
                                <Link to={TSR_USERS}>
                                    <Icon type="contacts"/>
                                    <span>Usuarios TSR</span>
                                </Link>
                            </Menu.Item>


                </SubMenu>

                <Menu.Item key="logout">
                    <LogoutButton/>
                </Menu.Item>




            </Menu>



                :

                state.premiumClient === true ?

                    <Menu
                        onClick={handleClick}
                        openKeys={state.openKeys}
                        mode="inline"
                        theme="light"
                        inlineCollapsed={state.collapsed}
                        onOpenChange={onOpenChange}
                        className="menu"
                    >
                        <Menu.Item key="home">
                            <Link to="/">
                                <Icon type="home"/> <span>Home</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="deliveries">
                            <Link to={PREMIUM_CLIENTS_DELIVERIES}>
                                <Icon type="home"/> <span>Recolecciones</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="logout">
                            <LogoutButton/>
                        </Menu.Item>
                    </Menu>

                    :

                <Menu
                    onClick={handleClick}
                    openKeys={state.openKeys}
                    mode="inline"
                    theme="light"
                    inlineCollapsed={state.collapsed}
                    onOpenChange={onOpenChange}
                    className="menu"
                >

                    {(state.admin) && (
                        <Menu.Item key="home">
                            <Link to="/">
                                <Icon type="home"/> <span>Home</span>
                            </Link>
                        </Menu.Item>
                    )}

                    { (state.admin && state.role === 'superAdmin') && (
                        <Menu.Item>
                            <Link to={SUPER_ADMINISTRATOR_USERS_PROFILES}>
                                <Icon type="github"/>
                                <span>Perfiles Usuarios Admin</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {/*Seccion Recircular Admin*/}
                    {(state.admin && state.role === 'superAdmin') && (
                        <SubMenu key="recircular-admin"
                                 title={
                                     <span>
                                 <Icon type="user" />
                                 <span>Recircular Admin</span>
                             </span>
                                 }
                        >
                            <Menu.Item key="recircular-users">
                                <Link to={RECIRCULAR_USERS}>
                                    <Icon type="user"/>
                                    <span>Usuarios</span>
                                </Link>
                            </Menu.Item>

                        </SubMenu>
                    )}

                    {/*Seccion Usuarios*/}
                    {(state.admin) && (
                        <SubMenu
                            key="users-data"
                            title={
                                <span>
                            <Icon type="user"/>
                            <span>Usuarios</span>
                        </span>
                            }
                        >

                            {(state.admin && (state.role === 'superAdmin' || state.role === 'admin')) && (
                                <Menu.Item key="users">
                                    <Link to={USERS}>
                                <span>
                                    <Icon type="user"/>
                                    <span>Todos los Usuarios</span>
                                </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.role === 'superAdmin' || state.role === 'cityAdmin')) && (
                                <Menu.Item key="users-per-city">
                                    <Link to={CREATE_USERS_PER_CITY}>
                                <span>
                                    <Icon type="user"/>
                                    <span>Usuarios por ciudad</span>
                                </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && state.role === 'superAdmin') && (
                                <Menu.Item>
                                    <Link to={FILTER_USERS}>
                                        <Icon type="environment"/>
                                        <span>Usuarios por ciudad (SuperAdmin)</span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && state.role === 'superAdmin') && (
                                <Menu.Item key="traceability-users">
                                    <Link to={TRACEABILITY_USERS}>
                                <span>
                                    <Icon type="user"/>
                                    <span>Usuarios Trazabilidad</span>
                                </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.role === 'superAdmin' || state.role === 'admin')) && (
                                <Menu.Item key="commerce-users-reports">
                                    <Link to={COMMERCE_REPORTS}>
                                <span>
                                    <Icon type="bar-chart"/>
                                    <span>Reportes de entrega (Comercios)</span>
                                </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="premium-users">
                                    <Link to={PREMIUM_USERS}>
                                <span>
                                    <Icon type="user"/>
                                    <span>Usuarios Clientes Premium</span>
                                </span>
                                    </Link>
                                </Menu.Item>
                            )}
                        </SubMenu>
                    )}

                    {/*Seccion Recicladores con QR*/}
                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                        <SubMenu
                            key="commerce-users-data"
                            title={
                                <span>
                            <Icon type="qrcode"/>
                            <span>Recicladores con QR</span>
                        </span>
                            }
                        >

                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="deliveries">
                                    <Link to={COMMERCE_DELIVERIES}>
                                <span>
                                    <Icon type="user"/>
                                    <span>Entregas de material</span>
                                </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="qr-recyclers">
                                    <Link to={QR_RECYCLERS}>
                                <span>
                                    <Icon type="qrcode"/>
                                    <span>Nuevo Reciclador</span>
                                </span>
                                    </Link>
                                </Menu.Item>
                            )}

                        </SubMenu>
                    )}

                    {/*Seccion Recicladores*/}
                    {(state.admin) && (
                        <SubMenu
                            key={"recyclers-data"}
                            title={
                                <span>
                            <Icon type="team"/>
                            <span>Recicladores</span>
                        </span>
                            }
                        >

                            {(state.admin) && (
                                <Menu.Item key="recyclers">
                                    <Link to={RECYCLERS}>
                                <span>
                                    <Icon type="team"/>
                                    <span>Recicladores Registrados</span>
                                </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {
                                (state.admin && state.role === 'superAdmin') && (
                                    <Menu.Item>
                                        <Link to={FILTER_RECYCLERS}>
                                            <Icon type="snippets"/>
                                            <span>Recicladores Filtrados</span>
                                        </Link>
                                    </Menu.Item>
                                )
                            }

                        </SubMenu>
                    )}

                    {/*Seccion Tiendas sin Residuos*/}
                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                        <SubMenu
                            key="main-stores"
                            title={
                                <span>
                             <Icon type="bank"/>
                             <span>Mi Tienda sin Residuos</span>
                         </span>
                            }
                        >

                            {(state.admin && (state.role === 'superAdmin' || state.category === 'operative')) && (
                                <Menu.Item>
                                    <Link to={STORES_ANALYTICS}>
                                        <Icon type="stock"/>
                                        <span>Análisis de datos</span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.category === 'all' || state.category === 'operative') &&
                                (state.cityRole === 'all' || state.cityRole === 'Quito' || state.cityRole === 'Guayaquil' || state.cityRole === 'Cuenca')) && (
                                <Menu.Item key="stores_deliveries">
                                    <Link to={STORE_DELIVERIES}>
                                          <span>
                                            <Icon type="team"/>
                                            <span>Entregas de material</span>
                                          </span>
                                    </Link>
                                </Menu.Item>
                            )}

                        </SubMenu>
                    )}


                    {/*Seccion Datos Trazabilidad*/}
                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                        <SubMenu
                            key="traceability"
                            title={
                                <span>
                             <Icon type="double-right"/>
                             <span>Trazabilidad</span>
                         </span>
                            }
                        >

                            {(state.admin && (state.role === 'superAdmin')) && (
                                <Menu.Item>
                                    <Link to={ACTIVE_USERS}>
                                        <Icon type="smile"/>
                                        <span>Datos Usuarios ReciApp</span>
                                    </Link>
                                </Menu.Item>
                            )}


                            {/*{(state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'operative')) && (*/}
                            {/*    <Menu.Item>*/}
                            {/*        <Link to={STATION_WEIGHTS}>*/}
                            {/*            <Icon type="gold"/>*/}
                            {/*            <span>Pesos de entregas</span>*/}
                            {/*        </Link>*/}
                            {/*    </Menu.Item>*/}
                            {/*)}*/}

                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <SubMenu
                                    key="traceability-weights"
                                    title={
                                        <span>
                                    <Icon type="double-right"/>
                                    <span>Pesos</span>
                                </span>
                                    }
                                >

                                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                        <Menu.Item>
                                            <Link to={RECYCLING_STATIONS_WEIGHTS_PAGE}>
                                                <Icon type="gold"/>
                                                <span>Estaciones de Reciclaje</span>
                                            </Link>
                                        </Menu.Item>
                                    )}

                                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                        <Menu.Item>
                                            <Link to={RECICLATON_WEIGHTS_PAGE}>
                                                <Icon type="gold"/>
                                                <span>Reciclatones</span>
                                            </Link>
                                        </Menu.Item>
                                    )}

                                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                        <Menu.Item>
                                            <Link to={RECIAPP_WEIGHTS_PAGE}>
                                                <Icon type="gold"/>
                                                <span>ReciApp</span>
                                            </Link>
                                        </Menu.Item>
                                    )}

                                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                        <Menu.Item>
                                            <Link to={PREMIUM_WEIGHTS_PAGE}>
                                                <Icon type="gold"/>
                                                <span>Premium</span>
                                            </Link>
                                        </Menu.Item>
                                    )}

                                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                        <Menu.Item>
                                            <Link to={STORES_WEIGHTS_PAGE}>
                                                <Icon type="gold"/>
                                                <span>Tiendas</span>
                                            </Link>
                                        </Menu.Item>
                                    )}

                                </SubMenu>
                            )}
                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="managersTable">
                                    <Link to={MANAGERS_TRACEABILITY}>
                                  <span>
                                    <Icon type="file-search" />
                                    <span>Gestores</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}

                        </SubMenu>
                    )}


                    {/*Seccion Rutas de recoleccion*/}
                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                        <SubMenu
                            key="static-routes"
                            title={
                                <span>
                            <Icon type="branches"/>
                            <span>Rutas de recolección</span>
                        </span>
                            }
                        >

                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="mapbox">
                                    <Link to={MAKEROUTE}>
                                  <span>
                                    <Icon type="question-circle"/>
                                    <span>Hacer ruta para solicitudes</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item>
                                    <Link to={STATIC_ROUTES}>
                                <span>
                                    <Icon type="swap"/>
                                    <span>Rutas Estáticas</span>
                                </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item>
                                    <Link to={STATIC_ROUTES_DELIVERIES}>
                                        <Icon type="snippets"/>
                                        <span>Entregas de rutas</span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && state.role === 'superAdmin') && (
                                <Menu.Item>
                                    <Link to={TRACEABILITY_COLLECTORS_TRANSACTIONS}>
                                        <Icon type="car"/>
                                        <span>Recolectores Transacciones (Trazabilidad)</span>
                                    </Link>
                                </Menu.Item>
                            )}

                        </SubMenu>
                    )}

                    {/*Seccion Recolectores*/}
                    {(state.admin && (state.role === 'superAdmin')) && (

                        <Menu.Item key="collectors">
                            <Link to={COLLECTORS}>
                          <span>
                            <Icon type="car"/>
                            <span>Recolectores</span>
                          </span>
                            </Link>
                        </Menu.Item>

                    )}

                    {/*Seccion Intermediarios*/}
                    {(state.admin && state.role === 'superAdmin') && (
                        <SubMenu
                            key="intermediaries-data"
                            title={
                                <span>
                            <Icon type="reconciliation"/>
                            <span>Intermediarios</span>
                        </span>
                            }
                        >

                            {(state.admin && state.role === 'superAdmin') && (
                                <Menu.Item key="intermediaries">
                                    <Link to={INTERMEDIARIES}>
                                  <span>
                                    <Icon type="user-add"/>
                                    <span>Crear nuevo perfil</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && state.role === 'superAdmin') && (
                                <Menu.Item key="intermediaries_exchanges">
                                    <Link to={INTERMEDIARIES_TRANSACTIONS}>
                                  <span>
                                    <Icon type="team"/>
                                    <span>Transacciones</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}
                        </SubMenu>
                    )}

                    {/*Seccion Transformadoras*/}
                    {(state.admin && state.role === 'superAdmin') && (
                        <Menu.Item key="transformers">
                            <Link to={TRANSFORMERS}>
                          <span>
                            <Icon type="reconciliation" theme="filled"/>
                            <span>Transformadoras</span>
                          </span>
                            </Link>
                        </Menu.Item>
                    )}

                    {/*Seccion Asociaciones*/}
                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                        <Menu.Item key="associations">
                            <Link to={ASSOCIATIONS}>
                          <span>
                            <Icon type="bank"/>
                            <span>Asociaciones</span>
                          </span>
                            </Link>
                        </Menu.Item>
                    )}

                    {/*Seccion Campañas*/}
                    {(state.admin && state.role === 'superAdmin') && (
                        <SubMenu key="campaigns-menu"
                                 title={
                                     <span>
                                    <Icon type="notification"/>
                                    <span>Campañas</span>
                                 </span>
                                 }
                        >

                            {(state.admin && (state.role === 'superAdmin')) && (
                                <Menu.Item>
                                    <Link to={CAMPAIGNS}>
                                        <Icon type="notification"/>
                                        <span>Crear campañas</span>
                                    </Link>
                                </Menu.Item>
                            )}


                            {(state.admin && state.role === 'superAdmin') && (
                                <Menu.Item>
                                    <Link to={CAMPAIGNS_DELIVERIES}>
                                        <Icon type="snippets"/>
                                        <span>Entregas de campañas</span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {
                                (state.admin && state.role === 'superAdmin') && (
                                    <Menu.Item>
                                        <Link to={FIESTA_CERCA_COUPONS}>
                                            <Icon type="snippets"/>
                                            <span>Canjeos Fiesta Cerca</span>
                                        </Link>
                                    </Menu.Item>
                                )
                            }

                        </SubMenu>
                    )}

                    {/*Seccion Contenedores*/}
                    {(state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'operative')) && (
                        <Menu.Item>
                            <Link to={CONTAINERS}>
                                <Icon type="delete"/>
                                <span>Contenedores</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {/*Seccion Puntos de Entrega*/}
                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                        <SubMenu key="delivery-points"
                                 title={
                                     <span>
                                  <Icon type="rest"/>
                                  <span>Puntos de entrega</span>
                            </span>
                                 }>

                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="clean-points">
                                    <Link to={CLEAN_POINTS}>
                                        <Icon type="rest"/> <span>Puntos Limpios</span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="iglus">
                                    <Link to={IGLUS}>
                                        <Icon type="medicine-box"/> <span>Iglús</span>
                                    </Link>
                                </Menu.Item>
                            )}


                            {(state.admin && (state.category === 'all' || state.category === 'operative') && (state.cityRole === 'all' || state.cityRole === 'Cuenca')) && (
                                <Menu.Item key="pichaybots">
                                    <Link to={PICHAYBOTS}>
                                        <Icon type="rocket"/> <span>PichayBots</span>
                                    </Link>
                                </Menu.Item>
                            )}
                        </SubMenu>
                    )}

                    {/*Seccion control ciudades ReciApp*/}
                    {(state.admin && state.role === 'superAdmin') && (
                        <Menu.Item key="city-options">
                            <Link to={CITIES}>
                                <Icon type="global"/>
                                <span>Ciudades (ReciApp)</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {/*Seccion control ciudades Admin*/}
                    {(state.admin && state.role === 'superAdmin') && (
                        <Menu.Item key="city-admin-options">
                            <Link to={ADMIN_CITIES}>
                                <Icon type="global"/>
                                <span>Ciudades (Admin)</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {/*Seccion Estaciones de Reciclaje*/}
                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                        <SubMenu
                            key="recycling-stations"
                            title={
                                <span>
                            <Icon type="table"/>
                            <span>Estaciones de Reciclaje</span>
                        </span>
                            }
                        >

                            {(state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'operative')) && (
                                <SubMenu
                                    key="recycling-stations-profiles"
                                    title={
                                        <span>
                                    <Icon type="user-add"/>
                                    <span>Perfiles</span>
                                </span>
                                    }
                                >

                                    {(state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'operative')) && (
                                        <Menu.Item key="recycling-stations">
                                            <Link to={RECYCLING_STATIONS}>
                                          <span>
                                            <Icon type="qrcode"/>
                                            <span>Perfiles de estaciones de Reciclaje</span>
                                          </span>
                                            </Link>
                                        </Menu.Item>
                                    )}

                                    {(state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'operative')) && (
                                        <Menu.Item key="no-qr-profiles">
                                            <Link to={NO_QR_PROFILES}>
                                          <span>
                                            <Icon type="shop"/>
                                            <span>Perfiles sin QR</span>
                                          </span>
                                            </Link>
                                        </Menu.Item>
                                    )}

                                    {(state.admin && state.role === 'superAdmin') && (
                                        <Menu.Item key="customers">
                                            <Link to={CUSTOMERS}>
                                          <span>
                                            <Icon type="transaction"/>
                                            <span>Custodios Premium</span>
                                          </span>
                                            </Link>
                                        </Menu.Item>
                                    )}

                                </SubMenu>
                            )}

                            {(state.admin && (state.category === 'all' || state.category === 'operative') &&
                                (state.cityRole === 'all' || state.cityRole === 'Quito' || state.cityRole === 'Guayaquil' || state.cityRole === 'Cuenca')) && (
                                <SubMenu
                                    key="sub-create-groups-store"
                                    title={
                                        <span>
                                    <Icon type="shopping-cart"/>
                                    <span>Tiendas sin residuos</span>
                                </span>
                                    }
                                >

                                    {(state.admin && (state.category === 'all' || state.category === 'operative') &&
                                        (state.cityRole === 'all' || state.cityRole === 'Quito' || state.cityRole === 'Guayaquil' || state.cityRole === 'Cuenca')) && (
                                        <Menu.Item key="stores_deliveries">
                                            <Link to={STORE_DELIVERIES}>
                                          <span>
                                            <Icon type="team"/>
                                            <span>Entregas de material</span>
                                          </span>
                                            </Link>
                                        </Menu.Item>
                                    )}

                                    {
                                        (state.admin && (state.category === 'all' || state.category === 'operative') &&
                                            (state.cityRole === 'all' || state.cityRole === 'Quito' || state.cityRole === 'Guayaquil' || state.cityRole === 'Cuenca')) && (
                                            <Menu.Item>
                                                <Link to={STORES_DELIVERIES_REQUESTS}>
                                                    <Icon type="pic-right"/>
                                                    <span>Recolecciones Tiendas</span>
                                                </Link>
                                            </Menu.Item>
                                        )
                                    }

                                    {
                                        (state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'operative')) && (
                                            <Menu.Item>
                                                <Link to={TSR_USERS}>
                                                    <Icon type="contacts"/>
                                                    <span>Usuarios TSR</span>
                                                </Link>
                                            </Menu.Item>
                                        )
                                    }

                                </SubMenu>
                            )}

                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <SubMenu
                                    key="sub-create-groups-pams"
                                    title={
                                        <span>
                                    <Icon type="gold"/>
                                    <span>PAM-Iglús</span>
                                </span>
                                    }
                                >

                                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                        <Menu.Item key="igloos_pams_deliveries">
                                            <Link to={PAMS_DELIVERIES}>
                                          <span>
                                            <Icon type="bar-chart"/>
                                            <span>Entregas de material</span>
                                          </span>
                                            </Link>
                                        </Menu.Item>
                                    )}
                                </SubMenu>
                            )}

                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <SubMenu
                                    key="sub-create-groups-gathering"
                                    title={
                                        <span>
                                    <Icon type="reconciliation"/>
                                    <span>Centros de Acopio</span>
                                </span>
                                    }
                                >
                                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                        <Menu.Item key="gathering-center-deliveries">
                                            <Link to={CA_DELIVERIES}>
                                          <span>
                                            <Icon type="team"/>
                                            <span>Recolecciones Centros de Acopio</span>
                                          </span>
                                            </Link>
                                        </Menu.Item>
                                    )}
                                </SubMenu>
                            )}

                            {(state.admin && state.role === 'superAdmin') && (
                                <Menu.Item key="returnability">
                                    <Link to={RETURNABILITY}>
                                  <span>
                                    <Icon type="team"/>
                                    <span>Retornabilidad</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {
                                (state.admin && (state.category === 'all' || state.category === 'operative') && (
                                    <Menu.Item>
                                        <Link to={NO_QR_DELIVERIES}>
                                            <Icon type="inbox"/>
                                            <span>Entregas sin QR</span>
                                        </Link>
                                    </Menu.Item>
                                ))
                            }

                        </SubMenu>
                    )}

                    {/*Seccion Recinegocios*/}
                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                        <SubMenu
                            key="partners"
                            title={
                                <span>
                            <Icon type="shop"/>
                            <span>ReciNegocios</span>
                        </span>
                            }
                        >

                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="commerce">
                                    <Link to={COMMERCE}>
                                  <span>
                                    <Icon type="user-add"/>
                                    <span>Crear nuevo ReciNegocio</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="exchanges">
                                    <Link to={EXCHANGES}>
                                  <span>
                                    <Icon type="gift"/>
                                    <span>Canjes</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}
                        </SubMenu>
                    )}


                    {/*Seccion Entregas de Material*/}
                    {(state.admin) && (
                        <SubMenu
                            key="general-deliveries"
                            title={
                                <span>
                            <Icon type="dropbox"/>
                            <span>Entregas de material</span>
                        </span>
                            }
                        >

                            {(state.admin) && (
                                <Menu.Item key="deliveries">
                                    <Link to={DELIVERIES}>
                                  <span>
                                    <Icon type="shopping"/>
                                    <span>Entregas normales</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="requests">
                                    <Link to={REQUESTS}>
                                  <span>
                                    <Icon type="wechat"/>
                                    <span>Solicitudes de Recolección</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="subscriptions">
                                    <Link to={SUBSCRIPTIONS}>
                                  <span>
                                    <Icon type="trophy"/>
                                    <span>Suscripciones</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}
                        </SubMenu>
                    )}

                    {/*Seccion Experimentos*/}
                    {(state.admin && state.role === 'superAdmin') && (
                        <SubMenu
                            key="sub-create-groups-experiments"
                            title={
                                <span>
                            <Icon type="bar-chart"/>
                            <span>Experimentos</span>
                        </span>
                            }
                        >
                            {(state.admin && state.role === 'superAdmin') && (
                                <Menu.Item key="creategroups">
                                    <Link to={CREATEGROUPS}>
                                  <span>
                                    <Icon type="team"/>
                                    <span>Grupos 1</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && state.role === 'superAdmin') && (
                                <Menu.Item key="creategroups">
                                    <Link to={CREATEGROUPS2}>
                                  <span>
                                    <Icon type="team"/>
                                    <span>Grupos 2</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && state.role === 'superAdmin') && (
                                <Menu.Item key="experimentsProduct">
                                    <Link to={EXPERIMENTS_PRODUCTS}>
                                  <span>
                                    <Icon type="team"/>
                                    <span>Grupos Festividades</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && state.role === 'superAdmin') && (
                                <Menu.Item key="experiments_comparison">
                                    <Link to={EXPERIMENTS_COMPARISON}>
                                  <span>
                                    <Icon type="team"/>
                                    <span>Comparación Experimentos</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}
                        </SubMenu>
                    )}

                    {/*Seccion Reportes*/}
                    {(state.admin&& (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'administration')) && (
                        <SubMenu
                            key="sub1"
                            title={
                                <span>
                            <Icon type="bar-chart"/>
                            <span>Reportes</span>
                        </span>
                            }
                        >
                            {(state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'administration')) && (
                                <Menu.Item key="mostrecycleruser">
                                    <Link to={MOSTRECYCLERUSER}>
                                  <span>
                                    <Icon type="snippets"/>
                                    <span>Reporte de entregas</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'administration')) && (
                                <Menu.Item key="deliveriesheatmap">
                                    <Link to={DELIVERIESHEATMAP}>
                                  <span>
                                    <Icon type="heat-map"/>
                                    <span>Mapa de entregas</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'administration')) && (
                                <Menu.Item key="mostactiveusers">
                                    <Link to={MOSTACTIVEUSERS}>
                                  <span>
                                    <Icon type="snippets"/>
                                    <span>Usuarios más activos</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'administration')) && (
                                <Menu.Item key="usehabits">
                                    <Link to={HABITS}>
                                  <span>
                                    <Icon type="snippets"/>
                                    <span>Reporte de Hábitos de Uso</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}

                            {(state.admin && (state.role === 'superAdmin' || state.role === 'admin') && (state.category === 'all' || state.category === 'administration')) && (
                                <Menu.Item key="deliveriesforrecyclers">
                                    <Link to={DELIVERIESFORRECYCLERS}>
                                  <span>
                                    <Icon type="snippets"/>
                                    <span>Reporte de Entregas a Recicladores</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}
                        </SubMenu>
                    )}
                    {/*Seccion ReciApp*/}
                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                        <SubMenu
                            key="reciapp"
                            title={
                                <span>
                            <Icon type="mobile"/>
                            <span>Reciapp</span>
                        </span>
                            }
                        >

                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="whatarewedoing">
                                    <Link to={WHAT_ARE_WE_DOING}>
                                  <span>
                                    <Icon type="file-image"/>
                                    <span>¿Qué estamos haciendo?</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="recitips">
                                    <Link to={RECITIPS}>
                                  <span>
                                    <Icon type="smile"/>
                                    <span>ReciTips</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="campaignpartners">
                                    <Link to={CAMPAIGNS_PARTNERS}>
                                  <span>
                                    <Icon type="team"/>
                                    <span>Campañas con aliados</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="deliveriescampaignpartners">
                                    <Link to={CAMPAIGNS_PARTNERS_DELIVERIES}>
                                  <span>
                                    <Icon type="team"/>
                                    <span>Entregas Campañas con aliados</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}
                        </SubMenu>
                    )}
                    {/*Seccion Gráficos*/}
                    {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                        <SubMenu
                            key="graphics"
                            title={
                                <span>
                            <Icon type="global"/>
                            <span>Gráficos-Gerencial</span>
                        </span>
                            }
                        >

                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="weights">
                                    <Link to={GRAPHICS_ANALYSIS}>
                                  <span>
                                    <Icon type="bar-chart"/>
                                    <span>Pesos estrategias</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="weightsMaterials">
                                    <Link to={GRAPHICS_ANALYSIS_MATERIAL}>
                                  <span>
                                    <Icon type="gold"/>
                                    <span>Pesos por material</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="storesGraphics">
                                    <Link to={STORES_GRAPHICS}>
                                  <span>
                                    <Icon type="shop"/>
                                    <span>Botellas Entregadas</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {(state.admin && (state.category === 'all' || state.category === 'operative')) && (
                                <Menu.Item key="recyclersGraphics">
                                    <Link to={RECYCLERS_ANALYSIS}>
                                  <span>
                                    <Icon type="usergroup-add"/>
                                    <span>Recicladores/as Beneficiados/as</span>
                                  </span>
                                    </Link>
                                </Menu.Item>
                            )}
                        </SubMenu>
                    )}

                    {(state.admin ) && (
                        <Menu.Item key="about">
                            <Link to={ABOUT}>
                          <span>
                            <Icon type="question-circle"/>
                            <span>Acerca de...</span>
                          </span>
                            </Link>
                        </Menu.Item>
                    )}



                    <Menu.Item key="logout">
                        <LogoutButton/>
                    </Menu.Item>
                </Menu>

            }
        </>




    );
}

export default PrincipalMenu;
